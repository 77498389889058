import { createSlice } from "@reduxjs/toolkit";

export const appSlice = createSlice({
  name: "app",
  initialState: {
    appLoading: [],
    userData: {},
    alertData: {},
  },
  reducers: {
    startLoading: (state) => {
      state.appLoading = [true];
    },
    stopLoading: (state) => {
      state.appLoading = state.appLoading.slice(1);
    },
    updateUserData: (state, actions) => {
      state.userData = actions.payload;
    },
    updateAlertData: (state, actions) => {
      state.alertData = actions.payload;
    },
  },
});

export const { startLoading, stopLoading, updateAlertData } = appSlice.actions;
