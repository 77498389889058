import { Suspense } from "react";
import Loader from "component/loader";
import { Provider } from "react-redux";
import { configureStore } from "@reduxjs/toolkit";
import { combineReducers } from "redux";
import { ToastProvider } from "react-toast-notifications";

import { AppRoutes } from "./Routes";
import { appSlice } from "./Routes/slice";

import "antd/dist/antd.min.css";

import "./App.scss";
import "./styles/riot-font.css";

const reducer = combineReducers({
  // here we will be adding reducers
  app: appSlice.reducer,
});

const store = configureStore({
  reducer,
  devTools: true,
});

function App() {
  return (
    <Suspense fallback={<Loader />}>
      <Provider store={store}>
        <div className="App">
          <ToastProvider>
            <AppRoutes />
          </ToastProvider>
        </div>
      </Provider>
    </Suspense>
  );
}

export default App;
